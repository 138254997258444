
import Examination from "../components/exams/General";
import ProgressReport from "../components/exams/ProgressReport";
// eslint-disable-next-line 
import Template from "../components/exams/Template"

export default function Exams({ profile, exams, students, settings, shouldCall }) {
  return (
    <div className="flex w-full h-screen">
      {["KG 1", "KG 2", "Nursery 1", "Nursery 2"].includes(
        profile.class_assigned
      ) ? (
        <ProgressReport students={students} settings={settings} profile={profile} shouldCall={shouldCall}/>
      ) : (
        // <Template students={students} exams={exams} settings={settings} profile={profile} shouldCall={shouldCall}/>
        <Examination students={students} exams={exams} settings={settings} profile={profile} shouldCall={shouldCall}/>
      )}
    </div>
  );
}
