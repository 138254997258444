import _ from "lodash";
import { sheet } from "../assets/images/data-uri/broadsheet";
import pdfMake from "pdfmake";
import fonts from "../assets/fonts";
pdfMake.vfs = fonts;

pdfMake.fonts = {
  Sen: {
    normal: "Sen-Regular.ttf",
    bold: "Sen-Bold.ttf",
    italics: "Sen-Regular.ttf",
    bolditalics: "Sen-ExtraBold.ttf",
  },
};

export default function generate_score_sheet(
  cls_report,
  type,
  cls,
  division,
  term
) {
  const isSem = ["Basic 7", "Basic 8", "Basic 9"].includes(cls);
  const classAverage = _.sumBy(cls_report, cs => cs.overrall_aggregate) / (cls_report.length * 10)
  var rows = [];

  if (isSem) {
    rows.push([
      "Name",
      "MATH",
      "SCI",
      "ENG",
      "SOCL",
      "FRCH",
      "FANTE",
      "RME",
      "C.ARTS",
      "P & H",
      "CTECH",
      "ICT",
      "TOTAL",
      "POSITION",
    ]);
  } else {
    rows.push([
      "Name",
      "MATH",
      "SCI",
      "ENG",
      "PE/OWOP",
      "FRCH",
      "FANTE",
      "RME",
      "C.ARTS",
      "HISTORY",
      "ICT",
      "TOTAL",
      "POSITION",
    ]);
  }

  if (type === "plain") {
    if (isSem === false) {
      for (var std of cls_report) {
        rows.push([
          std.last_name + " " + std.other_names,
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
        ]);
      }
    } else {
      for (var sd of cls_report) {
        rows.push([
          sd.last_name + " " + sd.other_names,
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
          " ",
        ]);
      }
    }
  } else if (type === "report") {
    let _sorted = _.sortBy(cls_report, (rp) => rp.overrall_aggregate).reverse();
    if (isSem === false) {
      for (var rpd of _sorted) {
        rows.push([
          rpd.last_name + " " + rpd.other_names,
          rpd.mts,
          rpd.sts,
          rpd.ets,
          rpd.phts || rpd.owts || "",
          rpd.fts,
          rpd.fnts,
          rpd.rmts,
          rpd.crats,
          rpd.hts,
          rpd.ictts,
          rpd.overrall_aggregate,
          _sorted.indexOf(rpd) + 1,
        ]);
      }
    } else {
      for (rpd of _sorted) {
        rows.push([
          rpd.last_name + " " + rpd.other_names,
          rpd.mts,
          rpd.sts,
          rpd.ets,
          rpd.owts || "",
          rpd.fts,
          rpd.fnts,
          rpd.rmts,
          rpd.crats,
          rpd.phts,
          rpd.ctts,
          rpd.ictts,
          rpd.overrall_aggregate,
          _sorted.indexOf(rpd) + 1,
        ]);
      }
    }
  }

  const widths =
    isSem === false
      ? [205, 40, 30, 30, 40, 40, 40, 30, 40, 50, 30, 40, 55]
      : [205, 40, 30, 30, 40, 40, 40, 30, 40, 30, 40, 40, 40, 55];

  const dd = {
    pageOrientation: "landscape",
    paperSize: "A4",
    defaultStyle: {
      font: "Sen",
      fontSize: 10,
    },
    pageMargins: [15, 15, 15, 15],
    permissions: {
      printing: "highResolution", //'lowResolution'
      modifying: false,
      copying: false,
      annotating: true,
      fillingForms: true,
      contentAccessibility: true,
      documentAssembly: true,
    },
    content: [
      {
        image: sheet,
        width: 840,
        absolutePosition: { x: 0, y: 0 },
      },
      {
        text: "Class: " + cls + division + " | Term: " + term,
        fontSize: 10,
        absolutePosition: { x: 20, y: 85 },
      },
      {
        text: "Class Average:  " + Number(classAverage).toFixed(2),
        fontSize: 12,
        absolutePosition: { x: 680, y: 85 },
      },
      {
        text: "Generated at: " + new Date().toLocaleString(),
        fontSize: 10,
        absolutePosition: { x: 20, y: 100 },
      },
      {
        absolutePosition: { x: 20, y: 120 },
        fontSize: 8,
        table: {
          widths: widths,
          body: rows,
        },
      },
    ],
  };

  const ddp = {
    pageOrientation: "landscape",
    paperSize: "A4",
    defaultStyle: {
      font: "Sen",
      fontSize: 10,
    },

    pageMargins: [15, 15, 15, 15],
    content: [
      {
        image: sheet,
        width: 840,
        absolutePosition: { x: 0, y: 0 },
      },
      {
        text: "Class: " + cls + division + " | Term: " + term,
        fontSize: 10,
        absolutePosition: { x: 20, y: 85 },
      },
      {
        text: "Generated at: " + new Date().toLocaleString(),
        fontSize: 10,
        absolutePosition: { x: 20, y: 100 },
      },
      {
        absolutePosition: { x: 20, y: 130 },
        table: {
          widths: widths,
          body: rows,
        },
      },
    ],
  };
  if (type !== "plain") {
    pdfMake.createPdf(dd).print();
  } else {
    pdfMake.createPdf(ddp).print();
  }
}
