import React from "react";
import { StudentExamTile } from "../tiles";
import { getRemarks, getGrade, generateRemarks } from "../../functions";
import {
  attitudes,
  conducts,
  head_remarks,
  interests,
} from "../../assets/data/remarks";
import { MdTipsAndUpdates } from "react-icons/md";
import { BsArrowDownRightCircle } from "react-icons/bs";
import _ from "lodash";
import axios from "axios";
import { proxy, updateData, xmail, uploadData } from "../../endpoints";
import { v4 as idv4 } from "uuid";
import { INITIAL_EXAMS_STATE } from "../../assets/data/states";
import { Feedback } from "../modals/feedback";
import { Spin } from "../loader";
import { dbName } from "../../assets/data/states";

export default class Examination extends React.Component {
  constructor() {
    super();
    this.state = {
      mcs: 0,
      mes: 0,
      mts: 0,
      mgr: "F",
      mrm: "Buck Up",

      ecs: 0,
      ees: 0,
      ets: 0,
      egr: "F",
      erm: "Buck Up",

      cracs: 0,
      craes: 0,
      crats: 0,
      cragr: "F",
      crarm: "Buck Up",

      fcs: 0,
      fes: 0,
      fts: 0,
      fgr: "F",
      frm: "Buck Up",

      fncs: 0,
      fnes: 0,
      fnts: 0,
      fngr: "F",
      fnrm: "Buck Up",

      scs: 0,
      ses: 0,
      sts: 0,
      sgr: "F",
      srm: "Buck Up",

      /*
      owcs: 0,
      owes: 0,
      owts: 0,
      owgr: "F",
      owrm: "Buck Up",*/

      rmcs: 0,
      rmes: 0,
      rmts: 0,
      rmgr: "F",
      rmrm: "Buck Up",

      hcs: 0,
      hes: 0,
      hts: 0,
      hgr: "F",
      hrm: "Buck Up",

      ictcs: 0,
      ictes: 0,
      ictts: 0,
      ictgr: "F",
      ictrm: "Buck Up",

      phcs: 0,
      phes: 0,
      phts: 0,
      phgr: "F",
      phrm: "Buck Up",

      ctcs: 0,
      ctes: 0,
      ctts: 0,
      ctgr: "F",
      ctrm: "Buck Up",

      interest: "",
      remarks: "",
      attitude: "",
      conduct: "",
      ht_remarks: "",
      attendance:0,

      overrall_aggregate: 0,
      date_processed: new Date().toISOString(),
      selected: false,
      tselected: false,
      year: new Date().getFullYear(),
      opn: false,
      type: "",
      confirm_update: false,
      confirm_pss: false,
      message: "",
    };
  }

  getCourseScore = (name) => (event) => {
    if (
      parseFloat(event.target.value) > 100 ||
      parseFloat(event.target.value) < 0
    ) {
      this.setState({ ...this.state, [name]: 0 });
    } else {
      this.setState(
        { ...this.state, [name]: parseFloat(event.target.value) },
        () => this.getCourseTotal()
      );
    }
  };

  getCourseTotal = () => {
    this.setState(
      {
        ...this.state,
        mts: parseFloat(
          0.5 * (parseFloat(this.state.mcs) + parseFloat(this.state.mes))
        ),
        ets: parseFloat(
          0.5 * (parseFloat(this.state.ecs) + parseFloat(this.state.ees))
        ),
        sts: parseFloat(
          0.5 * (parseFloat(this.state.scs) + parseFloat(this.state.ses))
        ),
        crats: parseFloat(
          0.5 * (parseFloat(this.state.cracs) + parseFloat(this.state.craes))
        ),
        /*owts: parseFloat(
          0.5 * (parseFloat(this.state.owcs) + parseFloat(this.state.owes))
        ),*/
        ictts: parseFloat(
          0.5 * (parseFloat(this.state.ictcs) + parseFloat(this.state.ictes))
        ),
        hts: parseFloat(
          0.5 * (parseFloat(this.state.hcs) + parseFloat(this.state.hes))
        ),
        rmts: parseFloat(
          0.5 * (parseFloat(this.state.rmcs) + parseFloat(this.state.rmes))
        ),
        fts: parseFloat(
          0.5 * (parseFloat(this.state.fcs) + parseFloat(this.state.fes))
        ),
        fnts: parseFloat(
          0.5 * (parseFloat(this.state.fncs) + parseFloat(this.state.fnes))
        ),
        phts: parseFloat(
          0.5 * (parseFloat(this.state.phcs) + parseFloat(this.state.phes))
        ),
        ctts: parseFloat(
          0.5 * (parseFloat(this.state.ctcs) + parseFloat(this.state.ctes))
        ),
      },
      () => {
        this.setState(
          {
            ...this.state,
            mgr: getGrade(this.state.mts),
            egr: getGrade(this.state.ets),
            sgr: getGrade(this.state.sts),
            // owgr: getGrade(this.state.owts),
            ictgr: getGrade(this.state.ictts),
            cragr: getGrade(this.state.crats),
            hgr: getGrade(this.state.hts),
            rmgr: getGrade(this.state.rmts),
            fgr: getGrade(this.state.fts),
            fngr: getGrade(this.state.fnts),
            phgr: getGrade(this.state.phts),
            ctgr: getGrade(this.state.ctts),
          },
          () => {
            this.setState({
              ...this.state,
              mrm: getRemarks(this.state.mgr),
              crarm: getRemarks(this.state.cragr),
              erm: getRemarks(this.state.egr),
              srm: getRemarks(this.state.sgr),
              // owrm: getRemarks(this.state.owgr),
              ictrm: getRemarks(this.state.ictgr),
              rmrm: getRemarks(this.state.rmgr),
              hrm: getRemarks(this.state.hgr),
              frm: getRemarks(this.state.fgr),
              fnrm: getRemarks(this.state.fngr),
              phrm: getRemarks(this.state.phgr),
              ctrm: getRemarks(this.state.ctgr),
              overrall_aggregate:
                this.state.crats +
                this.state.phts +
                this.state.mts +
                this.state.sts +
                // this.state.owts +
                this.state.ets +
                this.state.fts +
                this.state.rmts +
                this.state.hts +
                this.state.ictts +
                this.state.fnts +
                this.state.ctts,
            });
          }
        );
      }
    );
  };

  selectStudent = (id) => {
    const student = _.find(this.props.students, (std) => std.student_id === id);
    const {
      student_id,
      other_names,
      last_name,
      photo,
      delivery_mail,
      guardian_phone,
      current_class,
      class_category,
      division,
    } = student;

    this.setState({
      student_id,
      last_name,
      other_names,
      photo,
      division,
      delivery_mail,
      guardian_phone,
      current_class,
      class_category,
      update_mode: false,
      show: false,
      ...INITIAL_EXAMS_STATE,
    });
  };

  getAttendance = async (id) => {
    const { data } = await axios.get(
      `${proxy}/attendance/history/${id}?term=${this.props.settings.currentTerm}&academic_year=${this.props.settings.academicYear}`,
      {
        headers: {
          dbName,
          xmail,
        },
      }
    );

    return _.filter(data, (dt) => dt.is_present === true).length;
  };

  getData = async () => {
    const { students, settings } = this.props;

    const csA = await this.getAttendance(this.state.student_id);

    const exam = this.state;
    delete exam.exams;

    return {
      id: idv4().slice(0, 50).replaceAll("-", ""),
      n_courses: this.props.profile?.category === "Lower Primary" ? 8 : 10,
      num_on_roll: students.length,
      ...exam,
      term: settings.currentTerm,
      academic_year: settings.academicYear,
      attendance: this.state.attendance || csA || 1,
      total_days:
        parseInt(settings.numberOfWeeks * 5) - settings.numberOfHolidays,
      next_term: settings.nextTermBegins,
    };
  };

  isProcessed = (id) => {
    const { currentTerm } = this.props.settings;
    return Boolean(_.find(
      this.props.exams,
      (ex) =>
        ex.student_id === id &&
        ex.term === currentTerm &&
        ex.division === this.props.profile?.division
    ))
  };

  submitReport = async () => {
    const data = await this.getData();
    this.setState({ ...this.state, loading: true });
    // console.log(data);
    if (this.state.update_mode) {
      // update Document
      updateData({
        path: "/exams",
        id: this.state.id,
        data,
      })
        .then(() =>
          this.setState(
            {
              ...this.state,
              message: "Exam Data Update Successful",
              opn: true,
              type: "success",
              loading: false,
            },
            () => this.props.shouldCall(new Date().getSeconds())
          )
        )
        .catch((err) => {
          this.setState({
            message: err.message,
            opn: true,
            type: "error",
            loading: false,
          });
        });
    } else {
      uploadData({
        path: "/exams/",
        data,
      })
        .then(() =>
          this.setState(
            {
              ...this.state,
              message: "Exam Data Successfully Added",
              opn: true,
              type: "success",
            },
            () => {
              this.props.shouldCall(new Date().getSeconds());
              this.setState({ ...this.state, loading: false });
            }
          )
        )
        .catch((err) => {
          this.setState({
            message: err.message,
            opn: true,
            type: "error",
          });
          this.setState({ ...this.state, loading: false });
        });
    }
  };

  getExams = async () => {
    const { data } = await axios.get(
      `${proxy}/exams/${this.props.profile.class_assigned}?academic_year=${this.props.settings.academicYear}`,
      {
        headers: {
          dbName,
          xmail,
        },
      }
    );
    return data;
  };

  processUpdate = async (id) => {
    const { currentTerm, academicYear } = this.props.settings;
    const exams = await this.getExams();
    const info = _.find(
      exams,
      (ex) =>
        ex.student_id === id &&
        ex.term === currentTerm &&
        ex.academic_year === academicYear
    );
    const thisStudent =
      _.find(this.props.students, (st) => st.student_id === id) || {};

    this.setState({
      ...info,
      photo: thisStudent?.photo,
      update_mode: true,
      show: false,
    });
  };

  render() {
    const shouldDisableFrench = this.state.class_category === "Lower Primary";
    const _inputs = [
      {
        course: "Mathematics",
        _foc: (
          <input
            className="bg-white p-1 mr-2 w-32 md:w-[130px]"
            required
            min={0}
            max={100}
            value={this.state.mcs}
            type="number"
            onChange={this.getCourseScore("mcs")}
          />
        ),
        _foe: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            required
            min={0}
            max={100}
            value={this.state.mes}
            type="number"
            onChange={this.getCourseScore("mes")}
          />
        ),
        _hoa: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.mts}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.mgr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.mrm}
            disabled
          />
        ),
      },
      {
        course: "English",
        _foc: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            required
            min={0}
            max={100}
            type="number"
            value={this.state.ecs}
            onChange={this.getCourseScore("ecs")}
          />
        ),
        _foe: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.ees}
            onChange={this.getCourseScore("ees")}
          />
        ),
        _hoa: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.ets}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.egr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.erm}
            disabled
          />
        ),
      },
      ["Basic 7", "Basic 8", "Basic 9"].includes(this.state.current_class) && {
        course:
          "Social Studies",
        _foc: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            required
            min={0}
            max={100}
            type="number"
            value={this.state.owcs}
            onChange={this.getCourseScore("owcs")}
          />
        ),
        _foe: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            required
            min={0}
            max={100}
            type="number"
            value={this.state.owes}
            onChange={this.getCourseScore("owes")}
          />
        ),
        _hoa: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.owts}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.owgr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.owrm}
            disabled
          />
        ),
      } ,
      {
        course: "Science",
        _foc: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.scs}
            onChange={this.getCourseScore("scs")}
          />
        ),
        _foe: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.ses}
            onChange={this.getCourseScore("ses")}
          />
        ),
        _hoa: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.sts}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.sgr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.srm}
            disabled
          />
        ),
      },
      {
        course: "RME",
        _foc: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            required
            min={0}
            max={100}
            type="number"
            value={this.state.rmcs}
            onChange={this.getCourseScore("rmcs")}
          />
        ),
        _foe: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            required
            min={0}
            max={100}
            type="number"
            value={this.state.rmes}
            onChange={this.getCourseScore("rmes")}
          />
        ),
        _hoa: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.rmts}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.rmgr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.rmrm}
            disabled
          />
        ),
      },
      !["Basic 7", "Basic 8", "Basic 9"].includes(this.state.current_class)
        ? {
          course: "History",
          _foc: (
            <input
              min={0}
              max={100}
              className="bg-white p-1 mr-2 w-[130px]"
              required
              type="number"
              value={this.state.hcs}
              onChange={this.getCourseScore("hcs")}
            />
          ),
          _foe: (
            <input
              min={0}
              max={100}
              className="bg-white p-1 mr-2 w-[130px]"
              required
              type="number"
              value={this.state.hes}
              onChange={this.getCourseScore("hes")}
            />
          ),
          _hoa: (
            <input
              className="bg-white p-1 mr-2 w-[130px]"
              value={this.state.hts}
              disabled
            />
          ),
          _grd: (
            <input
              className="bg-white p-1 mr-2 w-[130px]"
              value={this.state.hgr}
              disabled
            />
          ),
          _rm: (
            <input
              className="bg-white p-1 mr-2 w-[130px]"
              value={this.state.hrm}
              disabled
            />
          ),
        }
        : {
          course: "Career Tech",
          _foc: (
            <input
              min={0}
              max={100}
              className="bg-white p-1 mr-2 w-[130px]"
              required
              type="number"
              value={this.state.ctcs}
              onChange={this.getCourseScore("ctcs")}
            />
          ),
          _foe: (
            <input
              min={0}
              max={100}
              className="bg-white p-1 mr-2 w-[130px]"
              required
              type="number"
              value={this.state.ctes}
              onChange={this.getCourseScore("ctes")}
            />
          ),
          _hoa: (
            <input
              className="bg-white p-1 mr-2 w-[130px]"
              value={this.state.ctts}
              disabled
            />
          ),
          _grd: (
            <input
              className="bg-white p-1 mr-2 w-[130px]"
              value={this.state.ctgr}
              disabled
            />
          ),
          _rm: (
            <input
              className="bg-white p-1 mr-2 w-[130px]"
              value={this.state.ctrm}
              disabled
            />
          ),
        },
      {
        course: "French",
        _foc: (
          <input
            disabled={shouldDisableFrench}
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.fcs}
            onChange={this.getCourseScore("fcs")}
          />
        ),
        _foe: (
          <input
            min={0}
            disabled={shouldDisableFrench}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.fes}
            onChange={this.getCourseScore("fes")}
          />
        ),
        _hoa: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.fts}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.fgr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.frm}
            disabled
          />
        ),
      },
      {
        course: "Fante",
        _foc: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.fncs}
            onChange={this.getCourseScore("fncs")}
          />
        ),
        _foe: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.fnes}
            onChange={this.getCourseScore("fnes")}
          />
        ),
        _hoa: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.fnts}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.fngr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.fnrm}
            disabled
          />
        ),
      },
      {
        course: "Creative Arts",
        _foc: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.cracs}
            onChange={this.getCourseScore("cracs")}
          />
        ),
        _foe: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.craes}
            onChange={this.getCourseScore("craes")}
          />
        ),
        _hoa: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.crats}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.cragr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white p-1 mr-2 w-[130px]"
            value={this.state.crarm}
            disabled
          />
        ),
      },
      {
        course: "Computing",
        _foc: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.ictcs}
            onChange={this.getCourseScore("ictcs")}
          />
        ),
        _foe: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.ictes}
            onChange={this.getCourseScore("ictes")}
          />
        ),
        _hoa: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            type="text"
            value={this.state.ictts}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white mr-2 w-[130px]"
            type="text"
            value={this.state.ictgr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white mr-2 w-[130px]"
            type="text"
            value={this.state.ictrm}
            disabled
          />
        ),
      },
      this.state.current_class !== "Lower Primary" && {
        course: "PE",
        _foc: (
          <input
            min={0}
            max={100}
            className="bg-gray-50 p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.phcs}
            onChange={this.getCourseScore("phcs")}
          />
        ),
        _foe: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            required
            type="number"
            value={this.state.phes}
            onChange={this.getCourseScore("phes")}
          />
        ),
        _hoa: (
          <input
            min={0}
            max={100}
            className="bg-white p-1 mr-2 w-[130px]"
            type="text"
            value={this.state.phts}
            disabled
          />
        ),
        _grd: (
          <input
            className="bg-white mr-2 w-[130px]"
            type="text"
            value={this.state.phgr}
            disabled
          />
        ),
        _rm: (
          <input
            className="bg-white mr-2 w-[130px]"
            type="text"
            value={this.state.phrm}
            disabled
          />
        ),
      },
    ];
    // should disable french.
    return (
      <div className="flex flex-col md:flex-row p-3 flex-1 gap-2 max-h-[90vh]">
        {/* Big Screens */}
        <div className="hidden md:block md:w-1/4 h-full bg-white  overflow-y-scroll">
          <div className="p-3 space-y-2">
            {this.props.students?.map((std, idx) => (
              <StudentExamTile
                student={std}
                student_id={this.state.student_id}
                processed={this.isProcessed(std.student_id)}
                key={idx}
                select={this.selectStudent}
                processUpdate={this.processUpdate}
              />
            ))}
          </div>
        </div>
        {/* Big Screens */}
        <div className="hidden md:block md:w-5/6 overflow-y-scroll relative">
          <table className="table bg-white w-full table-fixed  text-xs md:text-sm">
            <thead className="font-medium sticky top-0 bg-white z-50">
              <tr className="font-semibold">
                <td className="p-3 border" colSpan={3}>
                  Student Name :{" "}
                  {(this.state.student_id &&
                    this.state.last_name + " " + this.state.other_names) ||
                    "No Student Selected"}
                </td>
                <td className="p-3 border  md:table-cell">
                  Processed :{" "}
                  {this.state.student_id &&
                    this.isProcessed(this.state.student_id)
                    ? "Yes"
                    : "No"}
                </td>
                <td className="p-3 border">
                  Aggregate: {this.state.overrall_aggregate}
                </td>
                <td className="p-3 border  md:table-cell">
                  Term: {this.props.settings.currentTerm}
                </td>
              </tr>
              <tr>
                <td className="p-3 border">Subjects</td>
                <td className="p-3 border">Class Score</td>
                <td className="p-3 border">Exams Score</td>
                <td className="p-3 border">Total Score</td>
                <td className="p-3 border md:table-cell">Grade</td>
                <td className="p-3 border md:table-cell">Remarks</td>
              </tr>
            </thead>
            <tbody>
              {_inputs.map((info, key) => (
                <tr
                  key={key}
                  className={`${key % 2 === 0 ? "border-y" : "bg-white"}`}
                >
                  <td className="p-3 border truncate items-center">
                    {info.course}
                  </td>
                  <td className="p-3 border  items-center">{info._foc}</td>
                  <td className="p-3 border items-center">{info._foe}</td>
                  <td className="p-3 border  items-center">{info._hoa}</td>
                  <td className="p-3 border  items-center table-cell">
                    {info._grd}
                  </td>
                  <td className="p-3 border  items-center table-cell">
                    {info._rm}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="p-2 border bg-gray-100" colSpan={2}>
                  <div className="flex flex-col space-y-1">
                    <p
                      className="text-xs cursor-pointer"
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          tselected: !this.state.tselected,
                        })
                      }
                    >
                      Interest
                    </p>

                    {this.state.tselected ? (
                      <select
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            interest: e.target.value,
                          })
                        }
                        value={this.state.interest}
                      >
                        <option value="">Select Interest</option>
                        {interests.map((value, key) => (
                          <option value={value} key={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        className="p-2  text-sm"
                        value={this.state.interest}
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            interest: e.target.value,
                          })
                        }
                        type="text"
                        placeholder="Interest"
                      />
                    )}
                  </div>
                </td>
                <td className="p-2 border bg-gray-100" colSpan={2}>
                  <div className="flex flex-col space-y-1">
                    <p
                      className="text-xs cursor-pointer"
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          tselected: !this.state.tselected,
                        })
                      }
                    >
                      Conduct
                    </p>
                    {this.state.tselected ? (
                      <select
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            conduct: e.target.value,
                          })
                        }
                        value={this.state.conduct}
                      >
                        <option value="">Select Conduct</option>
                        {conducts.map((value, key) => (
                          <option value={value} key={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        value={this.state.conduct}
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            conduct: e.target.value,
                          })
                        }
                        className="p-2  text-sm"
                        type="text"
                        placeholder="Conduct"
                      />
                    )}
                  </div>
                </td>
                <td className="p-1 border bg-gray-100" colSpan={2}>
                  <div className="flex flex-col space-y-1">
                    <p
                      className="text-xs cursor-pointer"
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          tselected: !this.state.tselected,
                        })
                      }
                    >
                      Attitude
                    </p>
                    {this.state.tselected ? (
                      <select
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            attitude: e.target.value,
                          })
                        }
                        value={this.state.attitude}
                      >
                        <option value="">Select Attitude</option>
                        {attitudes.map((value, key) => (
                          <option value={value} key={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        value={this.state.attitude}
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            attitude: e.target.value,
                          })
                        }
                        className="p-2  text-sm"
                        type="text"
                        placeholder="Attitude"
                      />
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="p-1 border bg-gray-100" colSpan={2}>
                  <div className="flex flex-col space-y-1">
                    <p className="text-xs">Teacher's Remarks</p>

                    <input
                      value={this.state.remarks}
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          remarks: e.target.value,
                        })
                      }
                      className="p-2 text-sm"
                      type="text"
                      placeholder="Class Teacher's Remarks"
                    />
                  </div>
                </td>
                <td className="p-1 border bg-gray-100" colSpan={2}>
                  {localStorage.getItem("isAdmin") !== null ? 
                  <div className="flex flex-col space-y-1">
                    <p
                      className="text-xs cursor-pointer"
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          selected: !this.state.selected,
                        })
                      }
                    >
                      Head Teacher's Remarks
                    </p>
                    {this.state.selected ? (
                      <select
                        name=""
                        id=""
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            ht_remarks: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Head Teacher</option>
                        {head_remarks(this.state.other_names + " " + this.state.last_name || " ").map(
                          (value, key) => (
                            <option value={value} key={key}>
                              {value}
                            </option>
                          )
                        )}
                      </select>
                    ) : (
                      <input

                        className="p-2  text-sm"
                        type="text"
                        value={this.state.ht_remarks}
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            ht_remarks: e.target.value,
                          })
                        }
                        placeholder="Head Teacher's Remarks"
                      />
                    )}
                  </div> :
                  <div className="flex flex-col space-y-1">
                    <p className="text-xs">Attendance</p>
                    <input type="number" value={this.state.attendance} min={0} max={70} onChange={(e) => this.setState({ ...this.state, attendance: parseInt(e.target.value) })} className="p-2 w-full bg-white border" placeholder="No. of days Present" />

                  </div>
                  }
                </td>
                <td className="text-center">
                  <button
                    title="Generate Remarks"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        ...generateRemarks(),
                      })
                    }
                  >
                    <MdTipsAndUpdates className="text-xl text-yellow-600" />
                  </button>
                </td>
                <td className="text-center">
                  <button
                    onClick={() => this.submitReport()}
                    disabled={!this.state.student_id}
                    className="bg-green-500 text-white p-2 rounded-sm text-sm flex items-center justify-center shadow-md"
                  >
                    {this.state.loading ? <Spin /> : "Update Report"}
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
          {this.state.opn && (
            <Feedback
              message={this.state.message}
              type={this.state.type}
              setOpen={() => this.setState({ opn: false })}
            />
          )}
        </div>

        {/* Small Screens - Student Select Tiles */}
        <div className="md:hidden w-[90vw] block relative p-2">
          <button
            className="w-full p-3 bg-white flex items-center justify-between"
            onClick={() =>
              this.setState({ ...this.state, show: !this.state.show })
            }
          >
            <p>Select Student</p> <BsArrowDownRightCircle />
          </button>
          {this.state.show && (
            <div className="absolute w-full flex flex-col p-4 gap-2 h-48 overflow-y-auto bg-white shadow-md top-12">
              {this.props.students?.map((std, idx) => (
                <StudentExamTile
                  student={std}
                  student_id={this.state.student_id}
                  processed={this.isProcessed(std.student_id)}
                  key={idx}
                  select={this.selectStudent}
                  processUpdate={this.processUpdate}
                />
              ))}
            </div>
          )}
        </div>
        {/* Small Screens */}
        <div className="block md:hidden w-[90vw] bg-white">
          <table className="w-[90vw] table-fixed">
            <thead>
              <tr>
                <td className="p-3 border" colSpan={3}>
                  Student:{" "}
                  {(this.state.student_id &&
                    this.state.last_name + " " + this.state.other_names) ||
                    "No Student Selected"}
                </td>
              </tr>
              <tr>
                <th className="p-2">Subject</th>
                <th className="p-2">Class Score</th>
                <th className="p-2 truncate">Exams Score</th>
              </tr>
            </thead>
            <tbody>
              {_inputs.map((info, key) => (
                <tr key={key}>
                  <td className="p-3 border truncate items-center">
                    {info.course}
                  </td>
                  <td className="p-3 border overflow-hidden items-center">
                    {info._foc}
                  </td>
                  <td className="p-3 border  overflow-hidden items-center">
                    {info._foe}
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
          <div class="flex flex-col my-2">

            <td className="p-1 border bg-gray-100" rowSpan={2}>
              <div className="flex flex-col space-y-1">
                <p
                  className="text-xs cursor-pointer"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      tselected: !this.state.tselected,
                    })
                  }
                >
                  Interest
                </p>

                {this.state.tselected ? (
                  <select
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        interest: e.target.value,
                      })
                    }
                    value={this.state.interest}
                  >
                    <option value="">Select Interest</option>
                    {interests.map((value, key) => (
                      <option value={value} key={key}>
                        {value}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    className="p-1  text-sm"
                    value={this.state.interest}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        interest: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Interest"
                  />
                )}
              </div>
            </td>
            <td className="p-1 border bg-gray-100" colSpan={2}>
              <div className="flex flex-col space-y-1">
                <p
                  className="text-xs cursor-pointer"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      tselected: !this.state.tselected,
                    })
                  }
                >
                  Conduct
                </p>
                {this.state.tselected ? (
                  <select
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        conduct: e.target.value,
                      })
                    }
                    value={this.state.conduct}
                  >
                    <option value="">Select Conduct</option>
                    {conducts.map((value, key) => (
                      <option value={value} key={key}>
                        {value}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    value={this.state.conduct}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        conduct: e.target.value,
                      })
                    }
                    className="p-1  text-sm"
                    type="text"
                    placeholder="Conduct"
                  />
                )}
              </div>
            </td>
            <td className="p-1 border bg-gray-100" colSpan={2}>
              <div className="flex flex-col space-y-1">
                <p
                  className="text-xs cursor-pointer"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      tselected: !this.state.tselected,
                    })
                  }
                >
                  Attitude
                </p>
                {this.state.tselected ? (
                  <select
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        attitude: e.target.value,
                      })
                    }
                    value={this.state.attitude}
                  >
                    <option value="">Select Attitude</option>
                    {attitudes.map((value, key) => (
                      <option value={value} key={key}>
                        {value}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    value={this.state.attitude}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        attitude: e.target.value,
                      })
                    }
                    className="p-1  text-sm"
                    type="text"
                    placeholder="Attitude"
                  />
                )}
              </div>
            </td>


            <td className="p-1 border bg-gray-100" colSpan={2}>
              <div className="flex flex-col space-y-1">
                <p className="text-xs">Teacher's Remarks</p>

                <input
                  value={this.state.remarks}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      remarks: e.target.value,
                    })
                  }
                  className="p-1 text-sm"
                  type="text"
                  placeholder="Class Teacher's Remarks"
                />
              </div>
            </td>
            {localStorage.getItem("isAdmin") !== null ? <td className="p-1 flex flex-col border bg-gray-100" colSpan={2}>
              <div className="flex flex-col space-y-1">
                <p
                  className="text-xs cursor-pointer"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      selected: !this.state.selected,
                    })
                  }
                >
                  Head Teacher's Remarks
                </p>
                {this.state.selected ? (
                  <select
                    name=""
                    id=""
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        ht_remarks: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Head Teacher</option>
                    {head_remarks(this.state.other_names + " " + this.state.last_name || " ").map(
                      (value, key) => (
                        <option value={value} key={key}>
                          {value}
                        </option>
                      )
                    )}
                  </select>
                ) : (
                  <input

                    className="p-1  text-sm"
                    type="text"
                    value={this.state.ht_remarks}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        ht_remarks: e.target.value,
                      })
                    }
                    placeholder="Head Teacher's Remarks"
                  />
                )}
              </div>
            </td> :
              <input type="number" value={this.state.attendance} onChange={(e) => this.setState({ ...this.state, attendance: parseInt(e.target.value) })} min={0} max={70} className="p-2 bg-white border" placeholder="No. of days Present" />
            }

            <td className="text-center">
              <button
                onClick={() => this.submitReport()}
                disabled={!this.state.student_id}
                className="bg-green-500 mt-3 text-white p-2 rounded-sm text-sm flex items-center justify-center shadow-md"
              >
                {this.state.loading ? <Spin /> : "Update Report"}
              </button>
            </td>

          </div>
        </div>
      </div>
    );
  }
}
