import { BsDownload, BsTrash } from "react-icons/bs";
import { useEffect, useState } from "react";
import _ from "lodash";
import nodata from "../assets/images/no-data.png";
import {
  deleteData,
  mailerPath,
  readDocuments,
  xmail,
  proxy,
} from "../endpoints";
import { dbName } from "../assets/data/states";
import { Feedback } from "../components/modals/feedback";
import { composeMailMessage } from "../functions/messages";
import axios from "axios";
import { getThisClassReport } from "../functions/get-data";
import { mockSheet } from "../functions/mock";
import generate_report from "../functions/generate-report";
import { getImage } from "../functions/misc";
import generate_score_sheet from "../functions/generate-score-sheet";
import { classes } from "../assets/data/classes";
import { terms, mocks } from "../assets/data/courses";
import { reportSheet } from "../functions/report-sheet";
import { progressSheet } from "../functions/generate-progress-repeat";
import { calculateAggregate, findGrade, findRemarks } from "../functions";
import generate_mock_sheet from "../functions/mock-broadsheet";

export default function Reports({ students, settings, profile }) {
  const [student, setStudent] = useState(students[0]);
  const [exam, setExam] = useState(null);
  const [mock, setMock] = useState(null);
  const [exams, setExams] = useState([]);
  const [term, setTerm] = useState(settings.currentTerm);
  const [desc, setDesc] = useState("")
  const [response, setResponse] = useState({
    message: "",
    opn: false,
    type: "",
  });

  const IS_PRESCHOOL_CLASS = profile?.class_assigned[0] !== "B"

  useEffect(() => {
    async function getExams() {
      let table = IS_PRESCHOOL_CLASS ? "progress" : "exams"

      readDocuments({
        path: `/${table}/${profile.class_assigned}?academic_year=${settings.academicYear}`,
        getData: setExams,
      });
    }
    getExams();
  }, [profile.class_assigned, settings.academicYear, IS_PRESCHOOL_CLASS]);

  const findExam = async (term) => {
    if (profile.class_assigned === "Basic 9") {
      const { data } = await axios.get(`${proxy}/mock/${student.student_id}`, {
        headers: {
          dbName,
          xmail,
        },
      });
      setTerm(term);

      // find exam for this terms
      const exam =
        _.find(
          data !== null ? data[0]?.report : [],
          (rp) =>
            rp.examType === term && rp.academicYear === settings.academicYear
        ) || null;
      // modify script here...
      setExam(exam?.results || null);

      if (exam) {
       
        let results = [
          [
            { text: "SUBJECT", alignment: "center", bold: true },
            { text: "MARKS", alignment: "center", bold: true },
            { text: "GRADE", alignment: "center", bold: true },
            { text: "REMARKS", alignment: "center", bold: true },
          ],
        ];
        for (var dt of exam.results) {
          results.push([
            { text: dt.subject, bold: true },
            { text: dt.mark, alignment: "center" },
            { text: findGrade(dt.mark), alignment: "center" },
            { text: findRemarks(findGrade(dt.mark)), alignment: "center" },
          ]);
        }

        // console.log(exam)
        setMock({
          results: results,
          aggregate: calculateAggregate(exam.results),
          photo: student.photo,
          academicYear: settings.academicYear,
          rawScore: exam.rawScore,
          profile: {
            name: student.last_name + " " + student.other_names,
            id: student.student_id,
            class: profile.class_assigned,
            remarks: exams?.remarks || " ", 
            description: `Report for ${exam?.examType}`
          },
        });
      }

      return;
    }

    setTerm(term);
    // console.log(exams);
    const data = _.find(
      exams,
      (std) => std.student_id === student.student_id && std.term === term
    );

    setExam(data || null);
  };

  const getRanking = () => {
    const ranks = _.filter(
      exams,
      (ex) => ex.term === term && ex.division === profile?.division
    );
    return _.sortBy(ranks, (ex) => ex.overrall_aggregate).reverse();
  };

  const getMockRanking = async () => {
    try {
      const { data } = await axios.get(
        `${proxy}/mock/get-history?term=${settings.currentTerm}&year=${settings.academicYear}&exam=${term}`,
        {
          headers: {
            dbName,
          },
        }
      );

      // console.log(data)
      generate_mock_sheet({
        report: data, 
        term:term, 
        description:desc
      })

      // return data



    } catch (err) {
      console.log(err);
    }
  };
  const deliverMail = async (info) => {
    const message = composeMailMessage(info);
    const subject =
      "Examination Report for " + info.last_name + " " + info.other_names;
    try {
      await axios.post(`${mailerPath}/send-mail`, {
        content: message,
        subject,
        target: info.delivery_mail,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const prepareAll = async () => {
    const cc = _.find(classes, (cs) => cs.value === profile.class_assigned);
    const classData = getThisClassReport(
      exams,
      term,
      cc?.category,
      profile?.division
    );
    const examsData = _.filter(
      exams,
      (ex) => ex.term === term && ex.division === profile?.division
    );
    const sheets = [];

    for (var ex of examsData) {
      await getImage(ex?.photo, ex);
      var results = reportSheet(classData, ex);
      sheets.push(results);
      // push page break.
      sheets.push({
        text: "",
        absolutePosition: { x: 30, y: 780 },
        pageBreak: "after",
      });
    }

    sheets.pop();
    try {
      generate_report(sheets, "print");
    } catch (err) {
      console.log(err);
    }
  };

  const printResult = async () => {
    if (profile.class_assigned === "Basic 9") {
      await getImage(mock.photo, mock);

      let preview = mockSheet(mock);
      generate_report(preview, "print");
      return;
    }

    const clsExams = _.filter(exams, (ex) => ex.division === profile?.division);
    const classData = getThisClassReport(
      clsExams,
      term,
      exam?.class_category,
      profile?.division
    );
    await getImage(exam.photo, exam);

    // check category before print
    const results = reportSheet(classData, exam);

    generate_report(results, "print");
  };

  const getScoreSheet = (type) => {
    const examsData = _.filter(
      exams,
      (ex) => ex.term === term && ex.division === profile?.division
    );
    if (type === "plain") {
      generate_score_sheet(
        students,
        "plain",
        profile.class_assigned,
        profile.division,
        term,
        false
      );
    } else {
      generate_score_sheet(
        examsData,
        "report",
        profile.class_assigned,
        profile.division,
        term,
        false
      );
    }
  };


  const prepareProgressReport = async () => {
    // find exams by division
    let sheets = []
    let feed = exams.filter(ex => ex.current_class === profile.class_assigned && ex.division === profile.division && ex.term === settings.currentTerm);
    
    for (var fd of feed) {
      await getImage(fd.photo, fd)
      var results = progressSheet(fd.records, fd)
      sheets.push(results)
      // push page break.
      sheets.push({
        text: "",
        absolutePosition: { x: 30, y: 780 },
        pageBreak: "after",
      });
    }

    generate_report(sheets, "print");

  }


  return (
    <div className="flex-1 relative flex flex-col md:flex-row p-3 max-h-[95vh] overflow-hidden">
      <div className="w-full h-2/3 md:w-1/5 p-3 md:h-full bg-white overflow-scroll">
        {students.map((info, idx) => (
          <div
            onClick={() => setStudent(info)}
            key={idx}
            className="flex cursor-pointer overflow-hidden flex-row items-center my-2 border-y shadow-sm rounded-md p-1 space-x-1"
          >
            <img
              src={info.photo}
              alt="Student"
              className="w-10 h-10 rounded-full object-cover border-2 border-gray-400"
            />
            <div className="-space-y-1">
              <h3 className="text-md truncate text-sm">
                {info.last_name + " " + info.other_names}
              </h3>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full  flex-1 bg-white border-x-2">
        <div className="flex overflow-x-auto p-2 space-x-2 border-b-2 bg-gray-50">
          {profile.class_assigned !== "Basic 9" &&
            terms.map((tr) => (
              <button
                key={tr}
                onClick={() => findExam(tr)}
                className={`w-1/3 ${term === tr ? "bg-yellow-500 text-black" : "bg-white"
                  } p-2 text-sm shadow-md`}
              >
                {tr}
              </button>
            ))}

          {profile.class_assigned === "Basic 9" &&
            mocks.map((tr) => (
              <button
                key={tr}
                onClick={() => findExam(tr)}
                className={`w-1/6 shrink-0  truncate ${term === tr ? "bg-yellow-500 text-black" : "bg-white"
                  } p-2 text-sm shadow-md`}
              >
                {tr}
              </button>
            ))}
        </div>
        {/* Profile Info */}
        <div className="flex flex-col md:flex-row h-full p-3 space-x-1">
          <div className="flex w-full md:w-1/3 flex-col items-center">
            <img
              alt="Student"
              src={student?.photo}
              className="w-28 h-28 md:w-36 md:h-36 bg-gray-100 object-cover shadow-md"
            />

            <h3 className="font-medium text-sm w-2/3 truncate mt-2">
              {student?.last_name + " " + student?.other_names}
            </h3>
            <p className="text-xs">{student?.student_id}</p>
          </div>
          <div className="flex-1 space-y-2 bg-white p-3">
            <h3 className="text-lg">Report for {term}</h3>

            {IS_PRESCHOOL_CLASS && <div className="h-2/3 pb-3 border overflow-y-auto">
              <table className="w-full table-auto">
                <tbody >
                  {exam?.records?.slice(0, 3).map((rcd, idx) => (
                    <>
                      <tr key={`${idx}_progress`}>
                        <td colSpan={2} className="font-bold p-3 text-base" >{rcd.category}</td>
                      </tr>
                      {rcd.data.map((dt, idx) => (
                        <tr className="border-b" key={idx}>
                          <td className="text-sm p-3" colSpan={3}>{dt.description}</td>
                          <td className="text-sm p-3">{dt.remarks}</td>
                        </tr>
                      ))}

                    </>

                  ))}
                </tbody>

                <tfoot className="border">
                  <tr>
                    <td></td>
                    <td className="p-1">
                      <button
                        onClick={() => { }}
                        disabled={exam === null}
                        className="p-2 bg-green-500 text-xs text-white w-full"
                      >
                        Print
                      </button>{" "}
                    </td>
                    <td className="p-1">
                      <button
                        onClick={() => { }}
                        disabled={exam === null}
                        className="p-2 bg-yellow-500 text-xs text-black whitespace-nowrap w-full"
                      >
                        Email
                      </button>
                    </td>
                    <td className="p-1">
                      <button
                        onClick={() =>
                          deleteData({
                            path: "/progress",
                            id: exam.id,
                          })
                            .then(() =>
                              setResponse({
                                message: "Record Successfully Deleted!",
                                type: "success",
                                opn: true,
                              })
                            )
                            .catch((err) => {
                              setResponse({
                                message: err.message,
                                type: "error",
                                opn: true,
                              });
                            })
                        }
                        className="p-2 bg-red-500 text-xs text-white w-full"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
              {IS_PRESCHOOL_CLASS && (
                <button onClick={prepareProgressReport} className="w-fit uppercase text-center hidden md:flex m-2 bg-gray-700 text-white p-2 overflow-scroll">
                  Print All Reports
                </button>)}

            </div>}


            {!IS_PRESCHOOL_CLASS && <table className="table-auto md:border w-full">
              <thead>
                <tr className="text-sm bg-gray-50">
                  <td className="p-3">Subject</td>
                  <td className="p-3 ">Marks</td>
                  <td className="p-3">Grade</td>
                  <td className="p-3 ">Remarks</td>
                </tr>
              </thead>
              <tbody>
                {profile.class_assigned !== "Basic 9" && exam !== null && (
                  <>
                    <tr>
                      <td className="p-3 text-sm border">Mathematics</td>
                      <td className="p-3 text-sm  border">{exam.mts}</td>
                      <td className="p-3 text-sm border">{exam.mgr}</td>
                      <td className="p-3 text-sm  border">{exam.mrm}</td>
                    </tr>
                    <tr>
                      <td className="p-3 text-sm border">English</td>
                      <td className="p-3 text-sm  border">{exam.ets}</td>
                      <td className="p-3 text-sm border">{exam.egr}</td>
                      <td className="p-3 text-sm  border">{exam.erm}</td>
                    </tr>
                    <tr>
                      <td className="p-3 text-sm border">Science</td>
                      <td className="p-3 text-sm  border">{exam.sts}</td>
                      <td className="p-3 text-sm border">{exam.sgr}</td>
                      <td className="p-3 text-sm  border">{exam.srm}</td>
                    </tr>
                    <tr>
                      <td className="p-3 text-sm border">ICT</td>
                      <td className="p-3 text-sm  border">{exam.ictts}</td>
                      <td className="p-3 text-sm border">{exam.ictgr}</td>
                      <td className="p-3 text-sm  border">{exam.ictrm}</td>
                    </tr>
                  </>
                )}

                {profile.class_assigned === "Basic 9" && exam !== null && (
                  <>
                    {exam?.map((info, idx) => (
                      <tr key={idx}>
                        <td className="p-3 text-sm border">{info?.subject}</td>
                        <td className="p-3 text-sm border">{info?.mark}</td>
                        <td className="p-3 text-sm border">
                          {findGrade(info?.mark)}
                        </td>
                        <td className="p-3 text-sm border">{findRemarks(findGrade(info.mark))}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={3} className="p-3 text-sm font-bold">
                        Aggregate
                      </td>
                      <td className="p-3 text-sm ">
                        {calculateAggregate(exam)}
                      </td>
                    </tr>
                  </>
                )}
                {exam === null && (
                  <tr>
                    <td colSpan={4} className="p-5">
                      <div className="flex items-center flex-col p-3">
                        <img src={nodata} alt="No Data" className="w-20 h-20" />
                        <p>No Reports Yet!</p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot className="border">
                <tr>
                  <td></td>
                  <td className="p-1">
                    <button
                      onClick={() => printResult()}
                      disabled={exam === null}
                      className="p-1 bg-green-500 text-xs text-white w-full"
                    >
                      Print
                    </button>{" "}
                  </td>
                  <td className="p-1">
                    <button
                      onClick={() => deliverMail(exam)}
                      disabled={exam === null}
                      className="p-1 bg-yellow-500 text-xs text-black whitespace-nowrap w-full"
                    >
                      Email
                    </button>
                  </td>
                  <td className="p-1">
                    <button
                      disabled
                      onClick={() =>
                        deleteData({
                          path: "/exams",
                          id: exam.id,
                        })
                          .then(() =>
                            setResponse({
                              message: "Record Successfully Deleted!",
                              type: "success",
                              opn: true,
                            })
                          )
                          .catch((err) => {
                            setResponse({
                              message: err.message,
                              type: "error",
                              opn: true,
                            });
                          })
                      }
                      className="p-1 bg-red-500 text-xs text-white w-full"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>}

            {response.opn && (
              <Feedback
                message={response.message}
                type={response.type}
                setOpen={() => setResponse({ opn: false })}
              />
            )}
          </div>
        </div>
      </div>
      {!IS_PRESCHOOL_CLASS && profile.class_assigned !== "Basic 9" && getRanking().length > 0 && (
        <div className="w-full md:w-1/5 relative h-full space-y-2 bg-white overflow-scroll p-2">
          <div className="w-full flex gap-2 justify-between items-center">
            <h3 className="text-sm">Ranking</h3>
            <button
              className="p-1 shrink-0 bg-green-500 flex items-center justify-center text-sm text-white shadow-md"
              onClick={() => prepareAll()}
            >
              Print All
            </button>
          </div>
          {getRanking().map((info, idx) => (
            <div
              key={idx}
              className="flex justify-between items-center bg-gray-50 shadow-md"
            >
              <div className="flex flex-row space-x-1">
                <img
                  alt="Student"
                  src={info?.photo}
                  className="w-10 h-10 object-cover bg-black"
                />
                <div className="-space-y-1 w-[80%] overflow-hidden">
                  <h3 className="text-md truncate">{info.last_name}</h3>
                  <p className="text-sm">
                    Total Score:{info.overrall_aggregate}
                  </p>
                </div>
              </div>
              <div className="-space-y-1 text-center mr-1">
                <p className="text-sm text-gray-800">#{idx + 1}</p>
                <div className="flex gap-2 items-center ">
                  <button>
                    <BsDownload className="text-sm" />
                  </button>
                  <button
                    onClick={() =>
                      deleteData({
                        path: "/exams",
                        id: info.id,
                      })
                        .then(() =>
                          setResponse({
                            message: "Record Successfully Deleted!",
                            type: "success",
                            opn: true,
                          })
                        )
                        .catch((err) => {
                          setResponse({
                            message: err.message,
                            type: "error",
                            opn: true,
                          });
                        })
                    }
                  >
                    <BsTrash color="red" size={10} />
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div className="fixed w-fit truncate bottom-0 border-t-2 p-2 space-x-2 bg-white">
            <button
              onClick={() => getScoreSheet("report")}
              className="text-xs p-1 bg-black text-yellow-400 w-[120px] rounded-sm"
            >
              Broad Sheet
            </button>
            <button
              onClick={() => getScoreSheet("plain")}
              className="text-xs p-1 bg-black text-yellow-400 w-[120px] rounded-sm"
            >
              Empty Sheet
            </button>
          </div>
        </div>
      )}

      {profile.class_assigned === "Basic 9" && (
        <div className="flex items-center overflow-hidden rounded-l-full  fixed bottom-10 right-5 w-auto">
          <input type="description" className="p-2 outline-none w-96 bg-gray-700 text-white" onChange={(e) => setDesc(e.target.value)} placeholder="Enter Description on Paper" />
       
          <button
          disabled={!desc}
          onClick={getMockRanking}
          className=" p-3 text-sm bg-blue-700 text-white z-50 "
        >
          Download BroadSheet
        </button>
        </div>
       
      )}

    </div>
  );
}
