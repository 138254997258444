import { sheet } from "../assets/images/data-uri/broadsheet";
import pdfMake from "pdfmake";
import fonts from "../assets/fonts";
pdfMake.vfs = fonts;

pdfMake.fonts = {
  Sen: {
    normal: "Sen-Regular.ttf",
    bold: "Sen-Bold.ttf",
    italics: "Sen-Regular.ttf",
    bolditalics: "Sen-ExtraBold.ttf",
  },
};

export default function generate_mock_sheet({ report, term, description }) {
  var rows = [];
  rows.push([
    "NAME",
    "MATH",
    "SCI",
    "ENG",
    "SOCL",
    "FRCH",
    "FANTE",
    "RME",
    "C.ARTS",
    "CTECH",
    "ICT",
    "RAW SC",
    "AGG."
  ]);

  for (var rpd of report) {
    // console.log(rpd)
    let temp = [];

    temp.push(rpd.name);
   
      for (let rp2 of rpd.result) {
        temp.push(rp2.mark);
      }
  
    temp.push(rpd.rawscore)
    temp.push(rpd.aggregate)
    rows.push(temp);
  }




  const widths = [205, 40, 30, 30, 40, 40, 40, 30, 40, 30, 40, 40, 40, 55];

  const dd = {
    pageOrientation: "landscape",
    paperSize: "A4",
    defaultStyle: {
      font: "Sen",
      fontSize: 10,
    },
    pageMargins: [15, 15, 15, 15],
    permissions: {
      printing: "highResolution", //'lowResolution'
      modifying: false,
      copying: false,
      annotating: true,
      fillingForms: true,
      contentAccessibility: true,
      documentAssembly: true,
    },
    content: [
      {
        image: sheet,
        width: 840,
        absolutePosition: { x: 0, y: 0 },
      },
      {
        text: "Class: Basic 9  ",
        fontSize: 10,
        absolutePosition: { x: 20, y: 85 },
      },
      {
        text: description?.toUpperCase(),
        fontSize: 12,
        bold:true,
        absolutePosition: { x: 480, y: 85 },
      },
      {
        text: "Generated at: " + new Date().toLocaleString(),
        fontSize: 10,
        absolutePosition: { x: 20, y: 100 },
      },
      {
        absolutePosition: { x: 20, y: 120 },
        fontSize: 8,
        table: {
          widths: widths,
          body: rows,
        },
      },
    ],
  };

  pdfMake.createPdf(dd).print();
}
